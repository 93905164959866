import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import customConstants from "../Globals";

import forensicLib from "../assets/js/forensicLib";
import {NotificationManager} from "react-notifications";
let _this;


export class LeftMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuClass: [false, false, false, false,false,false],
            loading: false,
            open: false
        };
        this.canJump = true;
        _this = this;
    }

    jumpToPage(routeName, incoming) {

        if (this.canJump === true) {

            this.canJump = false;

            let currentClasses = this.state.menuClass;

            currentClasses = currentClasses.map(function (v, k) {
                return false;
            });

            currentClasses[incoming] = true;
            // console.log(currentClasses);

            setTimeout(function () {
                _this.setState({menuClass: currentClasses}, () => {
                    // console.log(_this.state.menuClass);
                });

            }, 100);

            setTimeout(function () {
                _this.canJump = true;
            }, 1000);

            this.props.history.push('/' + routeName);
        }

    }

    componentDidMount() {
        this.setState({
            specialityNo: sessionStorage.getItem('specialityNo'),
            caseId: sessionStorage.getItem('caseId'),
            requestAuthority: sessionStorage.getItem('requestAuthority')
        });
        const urlArray = window.location.href.split('/');
        const routeName = urlArray[urlArray.length - 1];

        let currentClasses = this.state.menuClass;
        currentClasses = currentClasses.filter(function (v, k) {
            return v == true;
        });

        if (routeName === 'dashboard' && currentClasses.length === 0) {
            currentClasses[0] = true;
        }

        if (routeName === 'sign-groups' && currentClasses.length === 0) {
            currentClasses[1] = true;
        }

        if (routeName === 'tools' && currentClasses.length === 0) {
            currentClasses[9] = true;
        }


        _this.setState({menuClass: currentClasses}, () => {
            // console.log(_this.state.menuClass);
        });

    }

    render() {

        return (
            <div style={{position: 'fixed', width: 225, zIndex: 999}}>

                <ul
                    className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
                    id="accordionSidebar">

                    < div
                        className={'menu-container'}>

                        < a
                            className="sidebar-brand d-flex align-items-center justify-content-center"
                            style={
                                {
                                    marginTop: -1
                                }
                            }
                            href="./">
                            < div
                                className="sidebar-brand-icon rotate-n-15">
                                < i
                                    className="fas fa-laugh-wink">
                                </i>
                            </div>

                            <
                                div>
                                < img
                                    src={'/assets/images/logo-' + customConstants.appName + '.png'}
                                    className={'dashboard-logo'}
                                    alt="IMG"/>
                                {(this.state.caseId && this.state.caseId !== 'undefined') ?
                                    <div className={'dashboard-info'}>
                                        <label>{global.strings.caseNo}</label> {this.state.caseId}
                                    </div> : null
                                }
                                {
                                    (this.state.specialityNo && this.state.specialityNo !== 'undefined') ?
                                        <div className={'dashboard-info'}>
                                            <label>{global.strings.speciality_no}</label> {this.state.specialityNo}
                                        </div> : null
                                }

                                {
                                    (this.state.requestAuthority && this.state.requestAuthority !== 'undefined') ?
                                        <div className={'dashboard-info'}>
                                            <label>{global.strings.request_authority}</label> {this.state.requestAuthority}
                                        </div> : null
                                }
                            </div>
                        </a>


                        {
                            (this.props.enableAdminMenu && this.props.enableAdminMenu === true && sessionStorage.getItem('reference') !== null && sessionStorage.getItem('reference').length > 0) ?
                                <li className="nav-item " onClick={() => this.jumpToPage('dashboard', 0)}>
                                    <a className={(this.state.menuClass[0] === true) ? 'nav-link active' : 'nav-link'}>

                                        <span>{global.strings.dashboard}</span></a>
                                </li> : null
                        }
                        {
                            (this.props.enableAdminMenu && this.props.enableAdminMenu === true && sessionStorage.getItem('reference') !== null && sessionStorage.getItem('reference').length > 0) ?
                                <li className="nav-item" onClick={() => this.jumpToPage('sign-groups', 1)}>
                                    <a className={(this.state.menuClass[1] === true) ? 'nav-link active' : 'nav-link'}
                                    ><span>{global.strings.signGroups}</span>
                                    </a>
                                </li> : null
                        }
                        {
                            (this.props.enableAdminMenu && this.props.enableAdminMenu === true && sessionStorage.getItem('reference') !== null && sessionStorage.getItem('reference').length > 0) ?
                                <li className="nav-item" onClick={() => this.jumpToPage('dashboard/compare', 2)}>
                                    <a className={(this.state.menuClass[2] === true) ? 'nav-link active' : 'nav-link'}
                                    ><span>{global.strings.compareSigns}</span>
                                    </a>
                                </li> : null
                        }

                        {
                            (this.props.enableAdminMenu && this.props.enableAdminMenu === true) ?
                                <li className="nav-item" onClick={() => this.jumpToPage('users', 6)}>
                                    <a className={(this.state.menuClass[6] === true) ? 'nav-link active' : 'nav-link'}
                                    ><span>{global.strings.users}</span>
                                    </a>
                                </li> : null
                        }
                        {
                            (this.props.enableAdminMenu && this.props.enableAdminMenu === true) ?
                                <li className="nav-item" onClick={() => this.jumpToPage('extra-evidence', 8)}>
                                    <a className={(this.state.menuClass[8] === true) ? 'nav-link active' : 'nav-link'}
                                    ><span>{global.strings.extraEvidence}</span>
                                    </a>
                                </li> : null
                        }
                        <li className="nav-item" onClick={() => this.jumpToPage('search', 3)}>
                            <a className={(this.state.menuClass[3] === true) ? 'nav-link active' : 'nav-link'}
                            ><span>{global.strings.searchMenu}</span>
                            </a>
                        </li>
                        < li
                            className="nav-item"
                            onClick={() => this.jumpToPage('register', 4)
                            }>
                            <
                                a
                                className={(this.state.menuClass[4] === true) ? 'nav-link active' : 'nav-link'
                                }
                            ><
                                span> {global.strings.registerNew}
    </span>
                            </a>
                        </li>
                        {
                            (this.props.enableAdminMenu && this.props.enableAdminMenu === true) ?
                                <li className="nav-item" onClick={() => this.jumpToPage('tools', 9)}>
                                    <a className={(this.state.menuClass[9] === true) ? 'nav-link active' : 'nav-link'}
                                    ><span>{global.strings.tools}</span>
                                    </a>
                                </li> : null
                        }
                    </div>

                </ul>
            </div>

        )
    }
}

export default withRouter(LeftMenu);

import React, {Component} from 'react';
import LeftMenu from "../components/LeftMenu";
import 'react-notifications/lib/notifications.css';
import "react-sweet-progress/lib/style.css";
import {logout} from "../Helpers";
import 'react-confirm-alert/src/react-confirm-alert.css';
import Loading from 'react-fullscreen-loading';
import customConstants from "../Globals";
import {NotificationManager} from "react-notifications";

let _this;


export class Tools extends Component {

    constructor(props) {
        super(props);
        this.state = {showReportPreview: false};
        this.caseUUID = sessionStorage.getItem('responseCaseId');
        this.firstImage = null;
        this.firstImagePreview = null;
        this.secondImage = null;
        this.secondImagePreview = null;
        this.copyImagePreview = null;
        this.copyImagePreviewResult = null;
        this.lastDownloadData = null;
        this.lastDownloadDataName = null;
        this.similarty = '---';
        this.cropImage = null;
    }

    componentDidMount() {
        this.setState({
            similarty: '---',
            firstImagePreview: null,
            secondImagePreview: null,
            cropImagePreview: null,
            cropImagePreviewResult: null
        });
    }

    handleFileChange = (event, imageKey) => {
        this.setState({similarty: '---'});
        const file = event.target.files[0];
        if (file) {
            this.setState({[imageKey]: file});
            const reader = new FileReader();
            reader.onload = () => {
                this.setState({[imageKey + 'Preview']: reader.result});
            };
            reader.readAsDataURL(file);

        }
    };

    handleFileChangeCrop = (event, imageKey) => {
        this.setState({copyImagePreviewResult: null});
        const file = event.target.files[0];
        if (file) {
            this.setState({cropImage: file});
            const reader = new FileReader();
            reader.onload = () => {
                this.setState({cropImagePreview: reader.result});
            };
            reader.readAsDataURL(file);
        }
    };

    downloadBase64File(base64Data, fileName) {
        const link = document.createElement('a');
        link.href = `data:image/jpeg;base64,${base64Data}`;
        link.download = fileName;
        document.body.appendChild(link);
        link.click(); // Tıklamayı tetikle
        document.body.removeChild(link);
    }

    handleUpload = async () => {

        const {firstImage, secondImage} = this.state;

        if (!firstImage || !secondImage) {
            NotificationManager.warning('Lütfen her iki resmi de seçin.');
            return;
        }
        this.setState({loading: true});
        const toBase64 = (file) => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

        try {
            const firstImageBase64 = await toBase64(firstImage);
            const secondImageBase64 = await toBase64(secondImage);

            const payload = {
                firstImage: firstImageBase64.replace('data:image/png;base64,', ''),
                secondImage: secondImageBase64.replace('data:image/png;base64,', ''),
            };


            const response = await fetch(customConstants.apiUrlAI + "/signature-detection/compare", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            this.setState({loading: false});

            if (response.ok) {
                NotificationManager.success('Benzerlik hesaplandı');
                const data = await response.json();
                this.setState({similarty: data.similarity.toFixed(4)});
            } else {
                NotificationManager.error('Bir hata oluştu.');
            }
        } catch (error) {
            this.setState({loading: false});
            console.error('Base64 dönüştürme veya yükleme hatası:', error);
            NotificationManager.error('Bir hata oluştu.');
        }
    };

    handleUploadCrop = async () => {
        const {cropImage} = this.state;

        this.setState({loading: true});
        const toBase64 = (file) => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

        try {
            const cropImageBase64 = await toBase64(cropImage);

            const payload = {
                image: cropImageBase64.replace('data:image/png;base64,', ''),
            };

            const response = await fetch(customConstants.apiUrlAI + "/signature-detection/document", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            this.setState({loading: false});

            if (response.ok) {
                const data = await response.json();
                if (data.isFound) {
                    NotificationManager.success('İmza başarıyla dışa çıkarıldı');
                    //this.downloadBase64File(data.image, cropImage.name)
                    this.setState({lastDownloadData:data.image, lastDownloadDataName:cropImage.name})
                    this.setState({cropImagePreviewResult: 'data:image/png;base64,' + data.image})
                } else {
                    NotificationManager.error('İmza Bulunamadı');
                }
                console.log(response)
            } else {
                NotificationManager.error('Bir hata oluştu.');
            }
        } catch (error) {
            this.setState({loading: false});
            console.error('Base64 dönüştürme veya yükleme hatası:', error);
            NotificationManager.error('Bir hata oluştu.');
        }
    };


    render() {

        return (
            <div>
                <Loading loading={this.state.loading} background="rgba(255,255,255,.7)" loaderColor="#0D386B"/>
                <div className="navbar navbar-expand navbar-light bg-white topbar  static-top shadow" id={'topBarMenu'}>

                    <div onClick={() => logout(this)} lang="en" className="logout-form-btn">
                        {global.strings.logout}
                    </div>

                </div>
                <div id="wrapper">

                    <LeftMenu enableAdminMenu={true}/>

                    <div className={'main-container'}>
                        <div className={'row'}>

                            <div style={{'backgroundColor': '#fff', 'margin': 0, 'width': '100%'}}>
                                <div className={'col-12'} style={{'display': 'flow-root'}}>
                                <span
                                    className={'dashboard-header'}>{global.strings.tools.toUpperCase()}</span>
                                </div>
                            </div>
                        </div>

                        <div className={'container'} style={{'padding': 0, 'maxWidth': '100%'}}>
                            <div className={''}>
                                <div className={'col-12'}>

                                    <div className={'standard-box'}>
                                        <div className={'col-12'} style={{'display': 'flow-root'}}>
                                        <span
                                            className={'dashboard-header'}>Statik İmza Karşılaştır (AI)</span>

                                        </div>
                                        <div className={'row'}>
                                            <div className={'col-5'} style={{'display': 'flow-root'}}>
                                                <div className={'col-12'} style={{fontSize: 12, fontWeight: 'bold'}}>
                                                    {global.strings.firstImage}
                                                </div>
                                                <div className={'col-12'}
                                                     style={{display: 'flex', flexDirection: 'column'}}>
                                                    <input
                                                        accept={'image/png;image/jpeg'}
                                                        type={'file'}
                                                        style={{fontSize: 12}}
                                                        onChange={(e) => this.handleFileChange(e, 'firstImage')}
                                                    />
                                                    {this.state.firstImagePreview !== null ?
                                                        <img
                                                            src={this.state.firstImagePreview}
                                                            alt="Seçilen Görsel"
                                                            style={{width: '200px', height: 'auto', marginTop: '10px'}}
                                                        /> : null
                                                    }
                                                </div>
                                            </div>
                                            <div className={'col-5'} style={{'display': 'flow-root'}}>
                                                <div className={'col-12'} style={{fontSize: 12, fontWeight: 'bold'}}>
                                                    {global.strings.secondImage}
                                                </div>
                                                <div className={'col-12'}
                                                     style={{display: 'flex', flexDirection: 'column'}}>
                                                    <input
                                                        type={'file'}
                                                        accept={'image/png;image/jpeg'}
                                                        style={{fontSize: 12}}
                                                        onChange={(e) => this.handleFileChange(e, 'secondImage')}
                                                    />
                                                    {this.state.secondImagePreview !== null ?
                                                        <img
                                                            src={this.state.secondImagePreview}
                                                            alt="Seçilen Görsel"
                                                            style={{width: '200px', height: 'auto', marginTop: '10px'}}
                                                        /> : null
                                                    }
                                                </div>
                                            </div>

                                            <div className={'col-2 d-flex'}>
                                                <label style={{fontSize: 16, lineHeight: 3}}>Benzerlik</label><span
                                                style={{
                                                    fontSize: 24,
                                                    fontWeight: 'bold',
                                                    color: 'red'
                                                }}>{this.state.similarty}</span>
                                            </div>
                                            <div className={'col-5'} style={{textAlign: 'center', marginTop: '20px'}}>
                                                <button
                                                    onClick={this.handleUpload}
                                                    style={{
                                                        padding: '10px 20px',
                                                        fontSize: 14,
                                                        backgroundColor: '#007bff',
                                                        color: 'white',
                                                        border: 'none',
                                                        borderRadius: '5px',
                                                        cursor: 'pointer',
                                                        margin: 11,
                                                        float: 'left'
                                                    }}
                                                >
                                                    Karşılaştır
                                                </button>
                                            </div>


                                        </div>


                                    </div>
                                    <div className={'standard-box'}>
                                        <div className={'col-12'} style={{'display': 'flow-root'}}>
                                        <span
                                            className={'dashboard-header'}>İmzayı İmajdan Ayır</span>

                                        </div>
                                        <div className={'row'}>
                                            <div className={'col-5'} style={{'display': 'flow-root'}}>
                                                <div className={'col-12'} style={{fontSize: 12, fontWeight: 'bold'}}>
                                                    İmza Resmi
                                                </div>
                                                <div className={'col-12'}
                                                     style={{'display': 'flex', flexDirection: 'column'}}>
                                                    <input
                                                        accept={'image/png;image/jpeg'}
                                                        type={'file'}
                                                        style={{fontSize: 12}}
                                                        onChange={(e) => this.handleFileChangeCrop(e)}
                                                    />
                                                    {this.state.cropImagePreview !== null ?
                                                        <div style={{maxHeight: 300}}><img
                                                            src={this.state.cropImagePreview}
                                                            alt="Seçilen Görsel"
                                                            style={{width: '200px', height: 'auto', marginTop: '10px'}}
                                                        /></div> : null
                                                    }
                                                </div>
                                            </div>
                                            <div className={'col-5'} style={{'display': 'flow-root'}}>
                                                <div className={'col-12'} style={{fontSize: 12, fontWeight: 'bold'}}>
                                                    Sonuç
                                                </div>
                                                <div className={'col-12'}
                                                     style={{'display': 'flex', flexDirection: 'column'}}>
                                                    {this.state.cropImagePreviewResult !== null ?
                                                        <>
                                                            <div style={{minHeight: 250}}>
                                                                <img
                                                                src={this.state.cropImagePreviewResult}
                                                                alt="Seçilen Görsel"
                                                                style={{width: '200px', height: 'auto', marginTop: '10px'}}
                                                            /></div>
                                                        </> : null
                                                    }

                                                </div>
                                            </div>


                                            <div className={'col-5'} style={{textAlign: 'center', marginTop: '20px'}}>
                                                <button
                                                    onClick={this.handleUploadCrop}
                                                    style={{
                                                        padding: '10px 20px',
                                                        fontSize: 14,
                                                        backgroundColor: '#007bff',
                                                        color: 'white',
                                                        border: 'none',
                                                        borderRadius: '5px',
                                                        cursor: 'pointer',
                                                        margin: 11,
                                                        float: 'left'
                                                    }}
                                                >
                                                    İmzayı Çıkar
                                                </button>
                                            </div>
                                            <div className={'col-5'} style={{textAlign: 'center', marginTop: '20px'}}>
                                                {this.state.cropImagePreviewResult !== null ?
                                                <button
                                                    onClick={() => this.downloadBase64File(this.state.lastDownloadData,this.state.lastDownloadDataName)}
                                                    style={{
                                                        padding: '10px 20px',
                                                        fontSize: 14,
                                                        width: 120,
                                                        backgroundColor: '#007bff',
                                                        color: 'white',
                                                        border: 'none',
                                                        borderRadius: '5px',
                                                        cursor: 'pointer',
                                                        margin: 11,
                                                        float: 'left'
                                                    }}
                                                >
                                                    İndir
                                                </button> : null }
                                            </div>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        )
            ;
    }

}

export default Tools;




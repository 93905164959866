import React, {Component} from 'react';
import { Switch, Route} from 'react-router-dom';
import {Login} from "./screens/login";
import {Selection} from "./screens/selection";
import {Register} from "./screens/register";
import {Upload} from "./screens/upload";
import {Search} from "./screens/search";
import {Reports} from "./screens/reports";
import {ExtraEvidence} from "./screens/extra-evidence";
import {Users} from "./screens/users";
import {DashBoard} from "./screens/dashboard";
import {Test} from "./screens/test";
import {Groups} from "./screens/groups";
import './App.css';
import {NotificationContainer} from "react-notifications";
import {Tools} from "./screens/tools";



class App extends Component {

    constructor(props) {
        super(props);

     }

    componentDidMount() {
    }

    render() {
        return (

            <div className="App">

                {/*}  <link rel="stylesheet" type="text/css" href={"/assets/css/style-"+customConstants.appName+".css"} /> */}
                <Switch>
                    <Route exact={true} path="/" component={Login}/>
                    <Route path="/selection" component={Selection}/>
                    <Route path="/upload" component={Upload}/>
                    <Route path="/users" component={Users}/>
                    <Route path="/register" component={Register}/>
                    <Route path="/extra-evidence" component={ExtraEvidence}/>
                    <Route path="/search" component={Search}/>
                    <Route path="/dashboard" component={DashBoard}/>
                    <Route path="/sign-groups" component={Groups}/>
                    <Route path="/dashboard/compare" component={DashBoard}/>
                    <Route path="/reports" component={Reports}/>
                    <Route path="/tools" component={Tools}/>
                    <Route path="/test" component={Test}/>
                </Switch>
                <NotificationContainer/>
            </div>


        );
    }
}

export default App;
